const middleware = {}

middleware['auth'] = require('../src/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['locale'] = require('../src/middleware/locale.js')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['redirection'] = require('../src/middleware/redirection.js')
middleware['redirection'] = middleware['redirection'].default || middleware['redirection']

middleware['require-auth'] = require('../src/middleware/require-auth.js')
middleware['require-auth'] = middleware['require-auth'].default || middleware['require-auth']

middleware['responsive'] = require('../src/middleware/responsive.js')
middleware['responsive'] = middleware['responsive'].default || middleware['responsive']

export default middleware
